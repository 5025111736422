import * as React from 'react';
import * as styles from '../../styles/global.module.css';

import { Box, Heading, VStack } from '@chakra-ui/react';

import { FunctionComponent } from 'react';
import Layout from '../components/Base/Layout';
import { SEO } from '../components/Base/Seo';

const CustomerAgreement: FunctionComponent = () => {
	return (
		<>
			<Layout>
				<Box
					maxW='1280px'
					margin='0px auto'
					pt='120px'
					pb='80px'
					px='20px'>
					<VStack pb='48px'>
						<Box
							className={styles.divider}
							w='1.5rem'
							height='0.13rem'
							color='red.500'
						/>
						<Box>
							<Heading
								as='h1'
								fontWeight='400'
								className={styles.FadeHeading}>
								Customer Agreement
							</Heading>
						</Box>
					</VStack>
					<VStack
						gap='1.25rem'
						alignItems='flex-start'>
						<Box>
							<p>
								Being over the age of 21, I hereby enter into this agreement
								(the “Agreement”) with Doctor Medica, for on and on behalf of
								itself and each Licensed Supplier and My Agents (defined below),
								intending to be legally bound:
							</p>
							<p>&nbsp;</p>
							<p>
								1.01 I am entering this Agreement with Doctor Medica because I
								wish to place an order (“My Order”) for certain medications and
								other products (“The Products”), on the terms and conditions set
								out herein.
							</p>
							<p>&nbsp;</p>
							<p>
								1.02 I want to purchase The Products from, and have My Order
								filled by, a Licensed Supplier in one or more of the following
								countries or geographic territories: Canada, Europe, and/or
								other locations around the world.
							</p>
							<p>&nbsp;</p>
							<p>
								1.03 I confirm, acknowledge and agree that, as part of the Order
								process, I have indicated that: (a) I want to purchase
								Medications and other products from, and have My Order filled
								by, a supplier in more than one of the listed countries (all
								countries selected by me are referred to hereafter as a
								“Selected Country”), Doctor Medica will, as my agent on my
								behalf, select a supplier, or where applicable, a licensed
								pharmaceutical supplier (each, a “Licensed Supplier”) from one
								or more countries to dispense The Products. Doctor Medica will,
								as my agent, make the decision about which one or more Licensed
								Suppliers will dispense The Products based on the availability
								and/or price of The Products in the Selected Countries; and (b)
								I want to purchase The Products from, and have My Order filled
								by, a Licensed Supplier in a specific Selected Country, The
								Products will be dispensed by a Licensed Supplier in that
								Selected Country selected for me by Doctor Medica, as my agent.
							</p>
							<p>&nbsp;</p>
							<p>
								1.04 I understand that Doctor Medica is not a pharmacy, and that
								in every case, I am purchasing The Products from the Licensed
								Supplier, and The Products will be shipped directly to me by the
								Licensed Supplier. If The Products are being purchased from
								pharmacies or pharmaceutical wholesalers in different countries,
								they will be shipped directly to me by the Licensed Supplier in
								that country.
							</p>
							<p>&nbsp;</p>
							<p>
								1.05 I confirm, acknowledge and agree that if The Products are
								shipped to me from more than one location, I will not be charged
								a separate shipping fee for each shipment.
							</p>
							<p>&nbsp;</p>
							<p>
								1.06 I specifically confirm, acknowledge and agree that title to
								The Products passes to me from the Licensed Supplier when The
								Products are picked up from the Licensed Supplier by a Third
								Party Shipping Provider contracted on my behalf, and that
								(subject expressly to Section 1.8 of Schedule “A” attached) any
								and all agreements reached or contracts formed throughout the
								course of my purchase of The Products are and shall be deemed to
								be made in respect of any of The Products that are purchased in
								a Selected Country, in that Selected Country and accordingly
								shall be governed by the laws of that Selected Country
								applicable to such contracts and agreements.
							</p>
							<p>&nbsp;</p>
							<p>
								1.07 I specifically confirm, acknowledge and agree that (subject
								expressly to Section 1.8 of Schedule “A” attached) any dispute
								that arises between me and Doctor Medica or any of My Agents
								(defined below) shall, insofar as such dispute relates to any of
								My Agents located in a Selected Country, be governed by the laws
								of that Selected Country applicable to contracts formed in that
								Selected Country and the courts of that Selected Country shall
								have sole and exclusive jurisdiction over any such dispute.
							</p>
							<p>&nbsp;</p>
							<p>
								1.08 The additional Terms and Conditions set out on Schedule “A”
								hereto, which Schedule is hereby incorporated herein by
								reference, form an integral part of this Agreement, and I
								acknowledge having read such terms and conditions and that I
								agree to them.
							</p>
						</Box>
						<Box>
							<b>Schedule “A”</b>
						</Box>
						<Box>
							<b>PART I – AUTHORIZATIONS AND CONSENTS</b>
						</Box>
						<Box>
							1.1 The authorizations, appointments, powers of representation and
							consents that I am providing herein to Doctor Medica and My Agents
							commence on the date I sign the Agreement and will continue until
							I cancel them. I understand that I can cancel the authorizations,
							appointments and consents I have herein granted at any time.
						</Box>
						<Box>
							1.2 I hereby authorize and appoint Doctor Medica and My Agents as
							my agents and attorneys for the limited purpose of taking all
							steps and signing all documents on my behalf necessary to obtain
							The Products, if required by law in a Selected Country from which
							I am purchasing The Products, to the same extent as I could do
							personally if I were present taking those steps and signing those
							documents myself. This authorization includes, but is not limited
							to: collecting My Information (defined below) about me or the
							legal entity I represent; collecting similar information from my
							licensing body; and disclosing my Information to Doctor Medica’s
							employees, agents, contractors, subcontractors, affiliates and
							service providers, including without limitation any Agent
							Physician (defined below), any Licensed Supplier and any
							pharmacist in a Selected Country being engaged on my behalf
							(collectively, “My Agents”), as required, for the limited purpose
							of obtaining The Products and for My Order to be filled.
						</Box>
						<Box>
							1.3 In this Agreement, the term: (a) “My Information” means
							information about me or the legal entity which I represent
							(including, without limitation, my order history, license
							information), my contact and demographic information (including,
							without limitation, my full name, address and phone number) and
							payment information.
						</Box>
						<Box>
							1.4 I further consent to Doctor Medica and My Agents being able to
							contact one another to discuss my Personal Information, as it
							pertains to the supply and shipping of The Products. I understand
							that the reason for this consent is to provide My Agents with the
							full opportunity to conduct an independent analysis of whether My
							Order is appropriate, and discuss any potential complications that
							might arise. My Information and information concerning My Order
							will also be provided to any marketing company, on whose behalf
							Doctor Medica carries out its marketing and administrative
							services, in order to facilitate the processing of My Order and to
							establish and maintain my customer account. I further understand
							that my Information will not be used for any other reason, and
							will be kept in strict confidence.
						</Box>
						<Box>
							<Box>
								1.5 I hereby specifically acknowledge that I am aware that
								Doctor Medica will be transmitting my Information by electronic
								means (for example fax, or secure internet) to My Agents. I
								understand that the use of electronic means will enhance the
								efficiency and timeliness of processing My Order. I also
								understand that Doctor Medica, as a custodian of my Personal
								Information, will take precautions to protect my Personal
								Information from improper disclosure or use. I hereby consent to
								Doctor Medica’s transmission of my Personal Information by
								electronic means to My Agents.
							</Box>
						</Box>
						<Box>
							<Box>
								1.6 If I was directed to Doctor Medica’s services through an
								intermediary (for example, a pharmacy benefit manager, health
								management organization or other service provider, or a City or
								State or other group program), I hereby authorize Doctor Medica
								to release Personal Information to such an intermediary if
								required for quality assurance or auditing purposes, or to
								permit the processing of any claims on my behalf. It is my
								understanding that all such intermediaries will provide
								confidentiality covenants to Doctor Medica whereby they agree to
								hold any such information in strictest confidence and to abide
								by the privacy policies of Doctor Medica relating to the
								protection of my Information. I specifically consent to the
								transmission of the forgoing information to such intermediaries
								by electronic means.
							</Box>
						</Box>
						<Box>
							<Box>
								1.7 Subject specifically to Sections 1.05, 1.06, and of the
								Agreement, I authorize and appoint Doctor Medica and My Agents
								as my agents and attorneys for the purpose of taking all steps
								and signing all documents on my behalf necessary to package or
								re-package The Products and to arrange delivery of them to me,
								to the same extent as I could do if I were personally present
								taking those steps and signing those documents myself.
							</Box>
						</Box>
						<Box>
							<Box>
								1.8 I confirm, acknowledge and agree that I initiated contact
								with Doctor Medica and that Doctor Medica is not located in the
								United States. Without limiting this Section of the Agreement, I
								also confirm, acknowledge and agree that all services that I
								receive from Doctor Medica, My Agents and Licensed Suppliers are
								being received outside of the United States and that any
								transportation and importation of goods are solely my
								responsibility and liability.
							</Box>
						</Box>
						<Box>
							<Box>
								<b>PART 2 – DISCLOSURE AND REPRESENTATIONS</b>
							</Box>
						</Box>
						<Box>
							<Box>
								<p>
									2.1 I hereby represent and confirm to Doctor Medica, and to
									each of its affiliates, associates, related companies,
									subsidiaries and parent company and each of their respective
									directors, officers, shareholders, employees, contractors,
									subcontractors, successors and assigns and to My Agents that:
									(a) The Products are being purchased by me, who is a licensed
									medical professional or I am acting on behalf of an
									institution or other legal entity and maintain all necessary
									licenses, permits and approvals in my governing jurisdiction
									to order and receive the products I am, or will be requesting.
									I will provide proof of licensing to Doctor Medica in order to
									verify my/our standing as a licensed entity and eligibility to
									purchase from Doctor Medica. (b) I can make my own decisions
									to purchase The Products or I am duly authorized to make
									legally binding decisions on behalf of the legal entity,
									inBoxiduals or institutions I represent including but not
									limited to purchasing The Products and entering into this
									agreement on its behalf. (c) I am not seeking or relying on
									any medical information, advice or approval from Doctor Medica
									or My Agents. (d) I acknowledge that Doctor Medica and My
									Agents have relied and will continue to rely on the
									information and documentation that I am providing to them
									(including the Agreement, My Order, and My Profile) and I
									represent and confirm that I have fully and truthfully
									disclosed all pertinent information and documentation to
									Doctor Medica. I agree to notify Doctor Medica of any changes
									to my licensing or the licensing of the entity I am authorized
									to act on behalf of by providing an updated Profile. I
									understand that if I have provided incorrect or incomplete
									information to Doctor Medica or My Agents, my account may be
									terminated immediately at the sole discretion of Doctor
									Medica. (e)Calls may be monitored to ensure quality service.
								</p>
							</Box>
						</Box>
						<Box>
							<Box>
								<b>PART 3 – PURCHASE AND SALE TERMS</b>
							</Box>
						</Box>
						<Box>
							<Box>
								<p>
									3.1 Doctor Medica will charge my credit card through a third
									party , for the price of the products and shipping charges as
									posted on the doctormedica.co web site on or about the day My
									Order is processed and all other documentation necessary to
									enable the Licensed Supplier(s) to process my order has been
									received. In the event my payment is not authorized, Doctor
									Medica has the right to cancel My Order and attempt to provide
									me with notice of such cancellation.
								</p>
							</Box>
							<Box>
								<p>
									3.2 I confirm, acknowledge and agree that: (a) Any of The
									Products being purchased from a Licensed Supplier will be
									packaged as required by law in the jurisdiction of the
									Licensed Supplier. (b) Products may be returned or exchanged
									within thirty (30) days of purchase where applicable. Should
									it be necessary to return or exchange any product, I agree
									that I will contact Doctor Medica for approval prior to making
									any attempt to return or exchange a product. Returns or
									Exchanges will be accepted at the sole discretion of Doctor
									Medica. (c) Doctor Medica and My Agents reserve the right to
									refuse to assist me in obtaining My Order or any other order
									in their sole discretion, in which event I will be entitled to
									a refund for monies paid for such order; and (d) Neither
									Doctor Medica nor My Agents provide their agency or attorney
									services as a substitute for the advice of licensed legal
									counsel (e)I authorize Doctor Medica, My Agents and/or the
									Licensed Supplier to establish an account with a third party
									shipping and sorting facility so that I may receive product
									storage, shipping and ancillary administrative services from a
									third party shipping and sorting facility. (f)I expressly
									authorize Doctor Medica, My Agents and/or the Licensed
									Supplier to choose the third party shipping and sorting
									facility at its sole discretion on my behalf. Said third party
									shipping and sorting facility may perform tasks such as
									shipping, storage, and sorting of stocks owned by me that are
									being shipped by the third party facility for me on my behalf.
									(g)I understand that the third party shipping provider will
									pick up from the Licensed Supplier, store and ship my stock in
									my name and on my behalf and is shipping said stock to me at
									my request. (h)I acknowledge that any third party utilized for
									the transportation, storage or sorting of goods for me will be
									held responsible for any damages incurred from any services
									provided by the third party to me as the third party is acting
									on my behalf and is not owned, operated or controlled by
									Doctor Medica. Therefore I agree not to hold Doctor Medica
									liable for any damages or claims that may arise from the
									actions or services provided by the third party. I further
									agree and acknowledge that I will at all times be the importer
									of record for all products shipped using the third party. I
									recognize and acknowledge that the third party shipping and
									sorting facility will not import the products being shipped to
									me on my behalf and may not provide importation or brokerage
									services and may not assist in the importation of products
									shipped by the third party storage and sorting facility on my
									behalf.
								</p>
							</Box>
							<Box>
								<p>
									3.3 I confirm, acknowledge and agree that to the extent that
									my customer account and records can be considered to be owned
									by any person, same shall be owned by Doctor Medica.
								</p>
							</Box>
							<Box>
								<p>
									3.4 I specifically confirm, acknowledge and agree that each
									and every one of these terms and conditions (including,
									without limitation, my choice of selected country(ies), Third
									Party Shipping Providers and Licensed Supplier(s)) will
									automatically, and without further action by me or Doctor
									Medica, apply to and govern any future orders by me of
									medications or other products from Doctor Medica, unless I
									specifically indicate otherwise at the time of ordering such
									products. without limiting the foregoing, each authorization
									and consent provided by me in this agreement will continue
									until such authorization or consent is canceled(which can be
									done at any time).
								</p>
							</Box>
						</Box>
					</VStack>
				</Box>
			</Layout>
		</>
	);
};

export const Head = () => (
	<SEO
		title='Doctor Medica - Customer Agreement'
		description='This is Doctor Medicas customer agreement.'
	/>
);
export default CustomerAgreement;
